/* General styles */

body {
    margin: 0;
    background: #e5ebed;
    font-family: Public Sans, sans-serif;
}

.vacasa-broadcast-service {
    margin-top: 20px;
    padding: 40px 70px;
    max-width: 1360px;
}

.queue-container {
    background-color: white;
    border-radius: 5px;
    margin-top: 50px;
}

.broadcast-section-title {
    font-size: 21px;
    line-height: 25.2px;
    font-weight: 700;
    font-family: Public Sans, sans-serif;
    color: #2C3439;    
}

/* Antd override classes */

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #e5ebed;
  box-shadow: 0 -1px 0 #b2c2c8, 0 1px 0 #b2c2c8;
  border-radius: 0;
  height: 40px;
}

.ant-table-tbody > tr > td {
    background-color: #f5f7f8;
}

.ant-table table {
    border-spacing: 0 10px;
}

.ant-divider{
    border: 1px solid #E5EBED;
    margin: 36px 0;
}

.my-select-container .ant-select .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #E5EBED !important;
}

.radioButton {
    .ant-radio-checked .ant-radio-inner {
        background-color: #003349 !important;
        border-color: #003349 !important;
    }
}

.radioButtonDisabled {
    .ant-radio-checked .ant-radio-inner {
        background-color: #99ADB6 !important;
        border-color: #99ADB6 !important;
    }

    .ant-radio-inner:after {
        background-color: #fff !important;
        border-color: #003349 !important;
        opacity: 1 !important;
    }
}

.checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #003349 !important;
        border-color: #003349 !important;
    }
}

.checkboxDisabled {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #99ADB6 !important;
        border-color: #99ADB6 !important;
    }

    .ant-checkbox-inner:after {
        background-color: transparent !important;
        border-color: #fff !important;
        opacity: 1 !important;
    }
}

/* Base class for inputs & select */
.select-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.select-label, .input-label {
    font-family: Public Sans, sans-serif;
    font-weight: 700;
    line-height: 16px;
    color: #2c3439;
    margin-bottom: 7px;
    text-transform: uppercase;
}

.select-options {
    box-sizing: border-box;
    border: 1px solid #e5ebed;
    border-radius: 8px;
    height: 52px;
    color: #707579;
    padding: 0 16px;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background-position: calc(100% - 0.75rem) 50% !important;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg height='10' width='10' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z'/%3E%3C/svg%3E")
        no-repeat 100% #fff;
}


.review-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edit-icon {
    cursor: pointer;
    font-size: 20px;
    color: #2C3439;
}

.required-field {
    color: #d62e4f;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    font-size: 16px;
}

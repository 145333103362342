.titleContainer {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
}

.inputTitle {
    width: 802px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #E5EBED;
    font-size: 16px;
    padding-left: 10px;
}

.spanTitle {
    word-break: break-all;
    margin-top: 16px;
    line-height: 27px;
}

.button {
    border: none;
    border-radius: 4px;
    width: 150px;
    cursor: pointer;
    height: 40px;
    color: #FFF;
    background-color: #0078AB;
    padding: 8px 20px 8px 20px;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    line-height: 24px;
    &:hover {
        opacity: .9;
    }
}

.buttonDisabled {
    border: none;
    border-radius: 4px;
    width: 150px;
    cursor: not-allowed;
    height: 40px;
    color: #FFF;
    background-color: #0078AB;
    padding: 8px 20px 8px 20px;
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    line-height: 24px;
    opacity: .5;
}

.datePicker {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.datePickersContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.dateLabel {
    font-family: Public Sans, sans-serif;
    color: #2C3439;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
}

.inputDate {
    width: 156px;
    height: 46px;
}

.datesContainer{
    margin-top: 15px;
}

.reviewDates {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-family: Public Sans, sans-serif;
    font-size: 14px;
    word-break: break-all;
    display: inline-block;
}

.toDates {
    margin-left: 5px;
    margin-right: 5px;
    font-family: Public Sans, sans-serif;
    font-weight: 500;
}

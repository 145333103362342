.primaryButton {
    border-radius: 4px;
    padding: 8px 20px 8px 20px;
    background-color: #0078AB;
    color: #FFF;
    border: none;
    margin-right: 16px;
    cursor: pointer;
    font-weight: 600;
    font-family: Public Sans, sans-serif;
}

.modalButton {
    border-radius: 4px;
    padding: 8px 20px 8px 20px;
    background-color: #0078AB;
    color: #FFF;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-family: Public Sans, sans-serif;
}

.modalButtonLink {
    background: none;
    border: none;
    outline: none;
    color: #1677ff;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    font-family: Public Sans, sans-serif;
    transition: color 0.3s;
    font-size: 14px;
}

.modalButtonLink:hover {
    color: #69b1ff;
}

.secondaryButton {
    border-radius: 4px;
    padding: 8px 20px 8px 20px;
    background-color: #FFF;
    color: #0078AB;
    border: 1px solid #0078AB;
    cursor: pointer;
    font-weight: 600;
    font-family: Public Sans, sans-serif;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    font-family: Public Sans, sans-serif;
    margin-top: 32px;
    margin-bottom: 32px;
}

.buttonsSuccessContainer {
    display: flex;
    justify-content: center;
    font-family: Public Sans, sans-serif;
    margin-top: 32px;
    margin-bottom: 12px;
}

.linksContainer {
    display: flex;
    justify-content: center;
    font-family: Public Sans, sans-serif;
    margin-top: 0px;
    margin-bottom: 32px;
}

.modalContent {
    text-align: center;
}

.subTitleMessage {
    color: #0078AB;
    font-family: Public Sans, sans-serif;
    font-weight: 600;
    cursor: pointer;
}

.titleMessage {
    color: #003349;
    font-family: Public Sans, sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.descriptionMessage {
    margin-bottom: 23px;
    color: #2C3439;
    font-weight: 400;
    font-family: Public Sans, sans-serif;
}

.descriptionSuccessMessage {
    margin-bottom: 23px;
    color: #2C3439;
    font-weight: 400;
    font-family: Public Sans, sans-serif;
    padding-left: 43px;
    padding-right: 43px;
}

.imgBody {
    max-width: 100%;
    width: 137px;
    margin-bottom: 1rem;
}

.impactedUnitsContainer {
    display: flex;
}

.unitListSection {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
}

.unitListInput {
    padding: 10px;
    width: 520px;
    height: 127px;
    border-radius: 8px;
    border: 1px solid #E5EBED;
    overflow-y: scroll;
}

.inputsSection {
    width: 230px;
}

.unitCodeInput::placeholder {
    font-size: 13px;
}

.unitCodeInput {
    width: 186px;
    height: 46px;
    border: 1px solid #E5EBED;
    border-radius: 8px;
    margin-top: 7px;
    font-size: 16px;
    padding-left: 10px;
}

.selectUnitCodes {
    & > div {
        height: 50px;
        margin-top: 7px;
    }
    display: none !important;
}

.unitCodeContainer {
    margin-top: 0; // 20px when selectUnitCodes display is different from none
}

.pressEnterMessage {
    color: #707579;
    font-size: 10px;
    font-family: Public Sans, sans-serif;
    font-weight: 600;
}

.unitCodeInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.unitCodeLoader {
    margin-left: 10px;
}

.tag {
    margin: 5px
}

.impactedUnitsList {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px 35px;
    padding-inline-start: 0;

    & li {
        background-color: #f0f0f0;
        padding: 7px 2px;
        width: 150px;
        border-radius: 5px;
        text-align: center;
        font-weight: 500;
        font-family: Public Sans, sans-serif;
        font-size: 14px;
        word-break: break-all;
    }
}

.impactedUnitsCollapse {
    margin-top: 18px;
    margin-bottom: 10px;
}

.checkbox {
    margin-bottom: 10px;
    font-family: Public Sans, sans-serif;
    color: #2C3439;
}

.checkboxMessage {
    margin-left: 10px;
    font-weight: 700;
    color: #2C3439;
    font-family: Public Sans, sans-serif;
    line-height: 24px;
}

.checkboxContainer {
    margin-top: 25px;
    display: block;
}

.checkboxSubTitleMessage {
    font-family: Public Sans, sans-serif;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    color: #51585C;
}

.consumerSegmentationContainer {
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    margin-top: 50px;
    width: 775px;
    min-height: 100px;
    background-color: #F5F7F8;
    border-radius: 8px;
    overflow-y: auto;
}

.subTitle {
    font-family: Public Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #51585C;
    margin-top: 15px;
}

.content {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
}

.leftSection {
    display: flex;
    flex-direction: column;
    width: 210px;
}

.rightSection {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.sectionTitle {
    font-family: Public Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2C3439;
}

.emailSubTitle {
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 800;
    color: #2C3439;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.messageAdvice {
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #51585C;
    margin-bottom: 30px;
    margin-top: 10px;
}

.message {
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #51585C;
    margin-bottom: 30px;
}

.divider {
    margin-top: 0 !important;
    margin-bottom: 30px !important;
}

.emailSubjectInput {
    border-radius: 8px;
    padding: 17px 10px;
    border: 1px solid #E5EBED;
    font-family: Public Sans, sans-serif;
}

.emailMessageInput {
    max-width: 520px;
    max-height: 160px;
    width: 520px;
    height: 160px;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 8px;
    border: 1px solid #E5EBED;
    font-family: Public Sans, sans-serif;
}

.emailMessageInput::-webkit-input-placeholder .chatMessageInput::-moz-placeholder {
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    line-height: 24px;
}

.emailMessageInput:-ms-input-placeholder .chatMessageInput::-moz-placeholder {
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.emailMessageInput:-moz-placeholder .chatMessageInput::-moz-placeholder {
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.emailMessageInput::-moz-placeholder .chatMessageInput::-moz-placeholder {
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.chatMessageInput {
    max-width: 520px;
    max-height: 100px;
    width: 520px;
    height: 285px;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 8px;
    border: 1px solid #E5EBED;
    font-family: Public Sans, sans-serif;
}

.radioSection {
    margin-left: 10px;
    margin-top: 10px;
}

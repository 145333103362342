.linkContainer {
    display: flex;
    margin-left: auto;
    margin-right: -6px;
    button {
        border: 0;
        font-size: 0.875rem;
        border-bottom: 1px solid #ccd6db;
        color: #4c4c4c;
        cursor: pointer;
        background-color: transparent;
        padding: 16px 12px;
        width: 160px;
        text-align: center;
    }
    .activeLink {
        border-bottom: 2px solid #8099a4;
        color: #003349;
        font-weight: bold;
    }
}

.container {
    .inner {
        background: #ffffff;
        margin-top: 32px;
        min-height: 340px;
        padding: 32px;
        border-radius: 4px;
        .subHeader {
            display: flex;
            margin-bottom: 35px;
            margin-top: 20px;
            .filters {
                display: flex;
            }
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            letter-spacing: -0.3px;
            color: #2c3439;
            margin-bottom: 36px;
        }
    }
}

.checkboxMessage {
    margin-left: 10px;
    font-weight: 400;
    color: #2C3439;
    font-family: Public Sans, sans-serif;
    line-height: 20px;
}

.checkboxContainer {
    margin-top: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.messageText {
    font-family: Public Sans, sans-serif;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 14.1px;
    text-transform: uppercase;
    color: #2C3439;
    margin-top: 10px;
    margin-bottom: 15px;
}

.messageInput {
    font-family: Public Sans, sans-serif;
    width: 812px;
    height: 88px;
    border: 1px solid #E5EBED;
    border-radius: 8px;
    margin-top: 7px;
    font-size: 14px;
    padding-top: 10px;
    padding-left: 10px;
    resize: none;
    color: #707579;
}

.subTitle {
    font-family: Public Sans, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #51585C;
}

.checkboxGreen {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #008100;
        border-color: #008100;
    }
}

.checkboxBlue {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1890FF;
        border-color: #1890FF;
    }
}

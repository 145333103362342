.broadcastReasonsContainer {
    display: flex;
}

.reasonListSection {
    margin-left: 20px;
    margin-top: 30px;
}

.reasonListInput {
    padding: 10px;
    width: 548px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid #E5EBED;
    margin-top: 7px;
}

.inputsSection {
    width: 200px;
    margin-top: 30px;
}

.unitCodeInput {
    width: 175px;
    height: 40px;
    border: 1px solid #E5EBED;
    border-radius: 8px;
    margin-top: 7px;
    font-size: 16px;
    padding-left: 10px;
}

.selectReason {
    margin-top: 7px;
    & > div {
        height: 50px;
    }
}

.subTitle {
    font-family: Public Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #51585C
}

.broadcastReasonsSelected {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4px 12px;
    padding-inline-start: 0;

    & li {
        background-color: #f0f0f0;
        padding: 7px 0;
        width: 130px;
        border-radius: 5px;
        text-align: center;
        font-weight: 500;
        font-family: Public Sans, sans-serif;
        font-size: 14px;
        word-break: break-all;
    }
}

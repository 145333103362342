.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    font-family: Public Sans, sans-serif;
}

.backButton {
    cursor: pointer;
    width: 11%;
}

.arrowIcon {
    margin-right: 10px;
}

.titleContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.subTitleActive {
    color: #6ba342;
    font-style: italic;
}

.subTitleInactive {
    color: #2c3439;
    font-style: italic;
}

.createIncidentButton {
    border: none;
    border-radius: 4px;
    width: 150px;
    cursor: pointer;
    height: 40px;
    color: #FFF;
    background-color: #0078AB;
    padding: 8px 20px 8px 20px;
    font-family: Public Sans, sans-serif;
    font-weight: 600;
    line-height: 24px;
    &:hover {
        opacity: .9;
    }
}

.container {
    width: 878px;
    .inner {
        background: #ffffff;
        margin-top: 32px;
        min-height: 340px;
        padding: 32px;
        border-radius: 4px;
        .subHeader {
            display: flex;
            margin-bottom: 35px;
            margin-top: 20px;
            .filters {
                display: flex;
            }
        }
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            letter-spacing: -0.3px;
            color: #2c3439;
            margin-bottom: 36px;
        }
    }
}

.hide {
  display: none !important;
}

.buttonContainer {
    margin-top: 20px;
    display: flex;
    width: 878px;
}

.creatingBroadcast {
    margin-left: 10px;
}

.creatingBroadcastContainer {
    margin-top: 30px;
    margin-bottom: 20px;
}

.waitSpinner {
    margin: 0;
    height: 100vh;
    display: grid;
    place-items: center;
}

.divider {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
}
